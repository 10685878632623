<template>
    <div>
        <div class="card-title mb-0 mt-3 pt-3">
            <h4>{{$t('collective')}}</h4>
        </div>
        <div v-for="game in event.games" :key="game.title" class="list-item">
            <gameOptionStatistics :game="game" :optionsWithStatistics="optionsWithStatistics[game.id]"></gameOptionStatistics>
        </div>
    </div>
</template>

<script>
import gameOptionStatistics from './game-option-statistics'

export default {
    name: 'event-statistics',
    components: {
        gameOptionStatistics
    },
    props: {
        event: Object
    },
    data() {
        return {
            optionsWithStatistics: []
        }
    },
    created() {
        this.$bobwinHub.$on('eventStatisticsReceived', this.statisticsReceived);
        this.init();
    },
    beforeDestroy() {
        this.$bobwinHub.$off('eventStatisticsReceived', this.statisticsReceived);
    },
    methods: {
        statisticsReceived (response) {
            this.optionsWithStatistics = this.splitByGame(JSON.parse(response.data));
        },
        splitByGame(statistics) {
            const dic = {};
            this.event.games.forEach((game) => {
                const list = [];
                game.options.forEach((option) => {
                    const found = statistics.find((e) => e.optionId == option.id);
                    if (found != null) {
                        list.push(found);
                    }
                });
                dic[game.id] = list;
            });
            return dic;
        },
        init() {
            this.CallHub({task: 'GetEventStatistics', callback: 'eventStatisticsReceived', data: this.event.id});
        }
    },
    watch: {
        'event': function(value){
            if (value != null && value.games != null) {
                this.init();
            }
        }
    }
}
</script>

<style scoped>
.list-item {
  display: block;
}
</style>