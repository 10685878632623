<template lang="html">
    <div v-if="optionsWithStatistics != null && optionsWithStatistics.length > 0" class="content game-options mt-2">
        <div >
          <h5>{{game.title}}</h5>
        </div>
        <div class="row">
            <div :class="optionSize(3)">
              <div><h6>{{$t('betOptionStatisticsByNumber')}}</h6></div>
              <div>
                <pieChart :reRender="reRender" :datacollection="dataCollectionForNumber" />
              </div>
            </div>
            <div :class="optionSize(3)">
              <div><h6>{{$t('betOptionStatisticsByAmount')}}</h6></div>
              <div>
                <pieChart :reRender="reRender" :datacollection="dataCollectionForAmount" />
              </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import pieChart from '@/components/charts/chartjs/pieChart';

export default {
  name: 'game-option-statistics',
  components: {
    pieChart
  },
  props: {
    game: Object,
    optionsWithStatistics: Array
  },
  data (){
    return {
      amountDic: {},
      numberDic: {},
      dataCollectionForAmount: {},
      dataCollectionForNumber: {},
      reRender: 0
    }
  },
  beforeDestroy: function(){
  },
  mounted() {
  },
  watch: {
    'optionsWithStatistics': function(value){
        if (value != null && value.length > 0) {
            this.init();
        }
    }
  },
  methods: {
    optionSize(optionLength){
      let size = optionLength == 3 ? 4 : 6;
      return "col-md-" + size;
    },
    init() {
      this.game.options.forEach(option => {
        const statistic = this.optionsWithStatistics.find(e => e.optionId === option.id);
        this.amountDic[option.title] = statistic == null ? 0 : statistic.amount;
        this.numberDic[option.title] = statistic == null ? 0 : statistic.count;
      });
      this.dataCollectionForAmount = this.getDataCollectionForAmount();
      this.dataCollectionForNumber = this.getDataCollectionForNumber();
      this.reRender++;
    },
    getLabels() {
        return  Object.keys(this.amountDic);
    },
    getDataForAmount() {
        return Object.values(this.amountDic);
    },
    getDataForNumber() {
        return Object.values(this.numberDic);
    },
    getBackgroundColor() {
      return [
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)'
      ];
    },
    getBorderColor() {
      return [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ];
    },
    getDataCollectionForAmount() {
        return {
        labels: this.getLabels(),
        datasets: [{
          data: this.getDataForAmount(),
          backgroundColor: this.getBackgroundColor(),
          borderColor: this.getBorderColor()
        }]
      }
    },
    getDataCollectionForNumber() {
        return {
        labels: this.getLabels(),
        datasets: [{
          data: this.getDataForNumber(),
          backgroundColor: this.getBackgroundColor(),
          borderColor: this.getBorderColor()
        }]
      }
    }
  }
}
</script>