<template>
  <section class="creategame">
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <div class="page-header">
        <h3 class="page-title" v-if="isVote">{{ $t('voteDetails') }}</h3> 
        <h3 class="page-title" v-else>{{ $t('betDetails') }}</h3> 
      </div>

      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card" :class="role">
            <div class="card-header">
              <div class="d-flex align-items-center">
                <div class="wrapper d-flex align-items-center">
                    <div class="wrapper d-flex align-items-center media-info">
                      <slot name="header-left">
                        <i :class="GetCategoryIcon(event.eventCategoryName)" v-b-tooltip.html.top :title="event.eventCategoryName" />
                        <h4 class="card-title ml-3">{{ GetEventCardTitle(event) }}</h4>
                      </slot>    
                    </div>
                    
                </div>
                <div class="wrapper ml-auto action-bar">
                  <slot name="header-right">
                    <div v-if="isVote" class="mt-2 text-right small text-muted">{{$t('eventDate')}}:<br>{{GetDate(event.startDate, 'YYYY-MM-DD HH:mm')}}</div>
                  </slot>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div v-if="event.teams.length == 2" class="row pb-4 mb-4 border-bottom">
                  <div class="col-4 text-left">
                      <h4>
                        <img :src="GetLogoUrl(event.teams[0].name)" class="img-md rounded-circle mr-2" />
                        {{event.teams[0].name}}
                      </h4>
                  </div>

                  <div class="col-4 start-time text-center">
                    <div  v-if="!isVote || !voteExpired">
                      <h3>{{sDatetime}}</h3>                      
                      <span>{{sDate}}</span>
                      </div>
                  </div>

                  <div class="col-4 text-right team-info">
                      <h4>
                        {{event.teams[1].name}}
                        <img :src="GetLogoUrl(event.teams[1].name)" class="img-md rounded-circle" />
                      </h4>
                  </div>
              </div>

              <div v-else class="start-time text-center pb-4 mb-4 border-bottom">
                  <h3>{{sDatetime}}</h3>
                  <span>{{sDate}}</span>
              </div>

              <div v-if="isVote" class="text-center pb-4 mb-4 border-bottom text-info">
                <div v-if="voteExpired && isPending"><h2>{{ $t('voteInProgress') }}</h2></div>
                <div v-else-if="voteExpired"><h2>{{ $t('votingEnded') }}</h2></div>
                <div v-else>
                  <div>{{ $t('votingEndsIn') }}</div>
                  <countdown :deadline="sVoteDeadline" />
                </div>
              </div>

              <div v-else class="text-center pb-4 mb-4 border-bottom text-info">
                <div v-if="event.closed">
                  <div class="text-warning mb-2">{{ $t('betClosed') }}</div>
                  <!--<b-button class="btn btn-success text-uppercase" @click.prevent="GoVoteDetail(event.id)"><i class="mdi mdi-playlist-check btn-icon-prepend"></i> {{ $t('goToVote') }}</b-button>-->
                </div>
                <div v-else-if="event.eventStatus == 0">
                  <div>{{ $t('betEndsIn') }}</div>
                  <countdown :deadline="sBettingEndDate" />
                </div>
              </div>


              <div v-for="game in event.games" :key="game.id" class="list-item">
                <div class="game-title text-info">
                  {{game.title}}
                </div>
                <div class="content game-options mt-2 league-event-details">
                    <event-card-game :game="game" :event="event" :viewType="viewType"></event-card-game>
                </div>
              </div>

              <event-statistics v-if="!!event" :event="event" ></event-statistics>

              <!--div v-if="isVote" class="list-item">
                <div class="game-title text-danger">
                  Report Invaild Event <small class="text-more-muted">Due to the event cancellation or something else</small>
                </div>
                <div class="d-flex flex-column mt-2">
                  <b-btn variant="inverse-danger" @click.prevent="reportEvent" :disabled="event.reportedByUserId" >
                      <div class="text-truncate text-uppercase">{{$t('report')}}</div>
                  </b-btn>
                </div>
                <div class="text-left small text-danger-muted" v-if="event.reportedByUserId">{{$t('reportedMessage', { reportDate: GetFormattedDatetime(event.reportedDate ? event.reportedDate : $moment()) })}}</div>
              </div-->

              <div class="mt-2 text-right small text-more-muted">Event ID: {{event.id}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import countdown from 'vuejs-countdown';
import eventCardGame from './share/event-card-game'
import eventStatistics from './share/event-statistics'
//import EventReport from "./event-report";

export default {
  name: 'event-detail',
  components: {
    countdown,
    eventCardGame,
    eventStatistics
    //EventReport
  },
  data() {
    return {
      viewType: 'bet',
      voteExpired: true,
      isPending: false,
      role: '',
      footerBgVariant: 'warning',
      eventId: '',
      rawEvent: [],
      event: {
        title: '',
        id: this.eventId,
        teams: [],
        games: []
      },
      loading: true,
      timer: null
    }
  },
  props: {
    compositeEvent: String
  },
  computed: {
      sDate() {
        return this.GetFormattedDate(this.event.startDate);
      },
      sDatetime(){
        return this.GetDate(this.event.startDate, 'HH:mm');
      },
      sVoteDeadline(){
        
        return this.GetDate(this.event.voteDeadline, 'YYYY-MM-DD HH:mm:ss');
      },
      sBettingEndDate(){
        return this.GetDate(this.event.endDate, 'YYYY-MM-DD HH:mm:ss');
      },
      isVote(){
        return this.viewType === 'vote';
    }
  },
  created() {
    this.$root.$on('reload', () => { this.init(); });
    this.$root.$on('betsucceed', () => { this.init(); });

    this.$bobwinHub.$on('eventReceived', this.eventReceived);

    this.$root.$on('updateBetStatus', option => {
      this.updateBetStatus(option);
    });    

    this.init();
  },
  beforeDestroy: function(){    
    this.$root.$off('updateBetStatus');
    this.$root.$off('reload');
    this.$root.$off('betsucceed');
    this.$bobwinHub.$off('eventReceived', this.eventReceived);
    clearInterval(this.timer);
    this.timer = null;
  },  
  methods: {
    eventReceived(response) {
      this.event = JSON.parse(response.data);
      if(this.isVote){
        this.isPending = this.event.games.filter(game => game.gameStatus === 3).length > 0;
        this.voteExpired = this.$moment().utc().isAfter(this.$moment.utc(this.event.voteDeadline));
      }
      this.updateOptionButton();
      this.event.closed = this.event.eventStatus != 0;
      this.eventStatusUpdater();
      this.runEventStatusUpdater();
      this.loading = false;
    },
    optionSize(optionLength){
      let size = optionLength == 3 ? 4 : 6;
      return "col-md-" + size;
    },
    updateOptionButton(){
      this.event.games.forEach((game) => {
          game.options.forEach((option) => {
            option.isInBetslip = this.GetBetslipIndex(option.id) > -1;
        });
      });
    },
    init(){
      this.loading = true;
      this.viewType = this.$route.name === 'votedetail' ? 'vote' : 'bet';
      this.role = this.isVote ? 'oracle' : 'bettor';
      
      this.eventId = this.compositeEvent != null ? this.compositeEvent : this.$route.params.eventId;
      if (this.eventId) {
        let param = {
          EventId: this.eventId,
          UserId: this.UserId,
          IncludeUserVoted: this.isVote,
          UserInput: this.compositeEvent != null ? 'once' : null
        };
        this.CallHub({task: 'GetEventDetails', callback: 'eventReceived', data: JSON.stringify(param)});
      }
    },
    updateBetStatus(option){
      let game = this.event.games.find(game => game.id == option.gameId);
      if(game){
        let gameOption = game.options.find(gameOption => gameOption.id === option.id);
        if(gameOption){
          gameOption.enableBet = option.enableBet;
          gameOption.message = option.message;
        }
      }
    },
    eventStatusUpdater() {
      if(!this.isVote) {
        if(!this.event.closed && this.$moment(this.$store.state.bobUTCTime).isAfter(this.$moment.utc(this.event.endDate))){
          this.event.closed = true;
          this.$set(this.event, this.event.closed, true);
          this.bettingDisable(this.$t('betClosed'));
        }
        if(!this.event.enableBet && this.$moment(this.$store.state.bobUTCTime).isAfter(this.$moment.utc(this.event.startDate))){
          this.bettingDisable(this.$t('betDisabled'));
        } else if (!this.event.enableBet) {
          this.bettingDisable('Already participated');
        }
      }
    },
    runEventStatusUpdater(){
      this.timer = setInterval(this.eventStatusUpdater, 3000 );
    },
    bettingDisable(message){
      this.event.games.forEach(game => {
        game.options.forEach(option => {
          if(option.enableBet){
            option.enableBet = false;
            option.updateType = 2;
            option.message = message;
          }
          });
      });
    },
    reportEvent(){
      if(this.IsLoggedIn) {
        this.$root.$emit('openReportDialog', this.event.id);
      } else {
        this.OpenConnectWalletModal();
      }
    },
    onReportEvent(){
      this.event.reportedByUserId = true;
    }
  },
  watch:{
    $route (){
      this.init();
      clearInterval(this.timer);
      this.timer = null;
    },
    'compositeEvent': {
        handler(newValue, oldValue){
            this.init();
            clearInterval(this.timer);
            this.timer = null;
            window.console.log(newValue, oldValue);
        },
        immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-add-game-type {
    background: #24272a;
}
.col-form-label {
  font-size: 0.875rem;
    line-height: 1;
    vertical-align: top;
}
.option-input {
  height: calc(1.55rem + 2px);
  label {
    font-size: 0.8rem;  
  }
}
.game-list {
  padding: 0; 
}
.game-options {
  width: 100%;
  padding-right: 0;
}
.list-item {
  display: block;
}
.card {
  .card-title {
    margin-bottom: 0;
  }
}
.team-info {
  i {
    margin-bottom: 0.4rem;
  }
}
.start-time {
  text-align:center;
  i {
    font-size: 60%;
    margin-bottom: 10px;
  }
}
</style>