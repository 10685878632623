<script>
import {
  Pie
} from 'vue-chartjs'

export default {
  extends: Pie,
  data () {
    return {
      options: {
        type: Object,
        default: () => { 
          return {
            responsive: true,
            animation: {
              animateScale: true,
              animateRotate: true
            }
          }
        }
      }
    }
  },
  props: {
    datacollection: Object,
    reRender: Number,
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  },
  watch: {
    reRender: function (newVal) {
      window.console.log(newVal);
      this.renderChart(this.datacollection, this.options);
    }
  },
}
</script>
